// app global css in SCSS form

@import "_vars";
@import "_mixins";
@import "_reset";
@import "_quasar-ui";
@import "_inputs";
@import "_btns";
@import "_typography";
@import "_onboarding";

html {
  font-size: 62.5%;
}

#q-app {
  background: var(--bodyBgColor);
  overflow: hidden;
}
