:root {
  --mainColor: #361C9F;
  --accentColor: #19CDA2;

  --headerBgColor: #FFFFFF;
  --headerPrimaryTypeColor: #18171A;
  --headerSecondaryTypeColor: #75737C;

  --bodyBgColor: #FFFFFF;
  --bodyPrimaryTypeColor: #18171A;
  --bodySecondaryTypeColor: #75737C;

  --surfaceBgColor2: #EDEBF2;
  --footerPrimaryTypeColor: #18171A;
  --footerSecondaryTypeColor: #75737C;

  --typeMainColor: #FFFFFF;
  --typeAccentColor: #FFFFFF;
  --typeErrorColor: #DF0000;
  --typeWarningColor: #FF9900;
  --typeSuccessColor: #1EB111;

  --font: "Inter", Arial, sans-serif;
  --font-heading: "Heading Pro", Arial, sans-serif;

  --init: transparent;
  --transition: .2s ease-in;
  --borderColor: #DCDCDC;
}
