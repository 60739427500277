.btn {
  width: max-content;

  cursor: pointer;
  display: flex;

  transition: var(--transition);

  background: transparent;
  border: none;

  text-transform: unset;
  text-decoration: none;
  font-family: var(--font);
  font-weight: 600;
  line-height: 150%;
  text-align: center;

  .q-btn__content {
    gap: 1.2rem;
  }
}

/*---------------------------------ПАЛИТРА------------------------------------*/

.btn_primary {
  background: var(--accentColor);
  color: var(--typeAccentColor);
  fill: var(--typeAccentColor);
}

.btn_secondary {
  background: transparent;
  color: var(--bodyPrimaryTypeColor);
  fill: var(--bodyPrimaryTypeColor);
  box-shadow: inset 0 0 0 1px var(--borderColor);
}

/*---------------------------------РАЗМЕРЫ------------------------------------*/

.btn_md {
  padding: 16px 30px;
  font-size: 1.6rem;
}
.btn_md:has(svg) {
  padding: 16px;
}
.btn_md:has(p):has(svg) {
  padding: 16px 20px 16px 30px;
}

.btn_sm {
  padding: 7px 16px;
  font-size: 1.4rem;
}
.btn_sm:has(svg) {
  padding: 7px;
}
.btn_sm:has(p):has(svg) {
  padding: 7px 12px 7px 16px;
}
