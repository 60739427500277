.h1, .h2, .h3, .h4 {
  font-family: var(--font-heading);
  margin: 0;
}
.h5, .h6, p {
  font-family: var(--font);
  margin: 0;
}

.h1 {
  font-weight: 500;
  font-size: 4.4rem;
  line-height: 123%;
  @include mobile {
    font-size: 4rem;
    line-height: 118%;
  }
}
.h2 {
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 131%;
  @include mobile {
    font-size: 3.6rem;
    line-height: 129%;
  }
}
.h3 {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 138%;
  @include mobile {
    font-size: 3.2rem;
    line-height: 133%;
  }
}
.h4 {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 170%;
}
.h5 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 150%;
}
.h6 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 143%;
}

p.body1 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
}
p.body2 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 143%;
}
p.caption {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 133%;
}
