// input
.q-field {

  &__control {
    padding: 0 16px;
    color: var(--accentColor);
    background-color: var(--surfaceBgColor2);
  }
  &__native {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
  }

  &--highlighted {
    .q-field {
      &__label {
        color: var(--bodySecondaryTypeColor);
      }
    }
  }
}

// checkbox
.q-checkbox {
  &__inner {
    font-size: 2.4rem;
    color: var(--accentColor);
    &--truthy {
      .q-checkbox {
        &__bg {
          background: transparent;
        }
      }
    }
  }
  &__bg {
    width: 75%;
    height: 75%;
    top: 16%;
    left: 16%;
    background: transparent;
  }
  &__svg {
    color: var(--accentColor);
  }
}

// radio
.q-toggle {
  &__inner {
    &--truthy {
      .q-toggle {
        &__track {
          opacity: .15;
          background-color: var(--accentColor);
        }
        &__thumb {
          &::after {
            background-color: var(--accentColor);
          }
        }
      }
    }
  }
}
