
.form {
  &__name {
    margin-bottom: 16px;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 12px;
  }
  &__actions {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > .btn {
      width: 100%;
    }
  }
}
.selector {
  cursor: pointer;
  margin-bottom: 20px;
}
.drawer {
  width: 460px;

  &__content {
    padding: 24px;
  }

  &__divider {
    margin: 24px 0;
    border-bottom: 1px solid var(--borderColor);
  }
}
