* {
  //transition: var(--transition);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.q-tab {
  &__label {
    margin-bottom: 16px;
    font-family: var(--font);
    font-weight: 600;
    font-size: 1.4rem;
    color: var(--bodyPrimaryTypeColor);
  }
  &__indicator {
    height: 3px;
    background-color: var(--accentColor);
  }
}
