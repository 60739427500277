.onboarding {
  z-index: 9999;
  max-width: 320px;
  padding: 20px;
  background-color: var(--bodyBgColor);
  border-radius: 4px;
  outline: none;

  .shepherd-header, .shepherd-text, .shepherd-footer {
    padding: 0 !important;
  }

  .shepherd-header {
    background: transparent !important;
  }

  .shepherd-title {
    margin-bottom: 12px;
    font-family: var(--font);
    line-height: 1em;
    font-weight: 600;
    font-size: 1.6rem;
  }
  .shepherd-text {
    line-height: 143%;
    font-weight: 400;
    font-size: 1.4rem;
  }
  .shepherd-footer {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

// arrow
.shepherd-element[data-popper-placement^=left]>.shepherd-arrow {
  right: -8px;
}
.shepherd-element[data-popper-placement^=right]>.shepherd-arrow {
  left: -8px;
}
.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
  top: -8px;
}
.shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
  bottom: -8px;
}
.shepherd-arrow, .shepherd-arrow:before {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1;
}
.shepherd-arrow:before {
  background-color: var(--bodyBgColor);
  content: "";
  transform: rotate(45deg);
}

// overlay background
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}
.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
  width: 100vw;
  z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transform: translateZ(0);
  transition: all .3s ease-out,height 0s 0s,opacity .3s 0s;
}
