
.drawer {
  width: 484px;
  pointer-events: none;
  transform: translateX(0);
  transition: var(--transition);
  opacity: 0;

  position: absolute;
  z-index: 1;
  top: 0;

  height: 100%;
  overflow: auto;

  background-color: var(--bodyBgColor);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

  &.active {
    pointer-events: auto;
    transform: translateX(-100%);
    opacity: 1;
  }

  &__content {
    height: 100%;
  }

  &__head {
    position: sticky;
    top: 0;

    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: inherit;
    color: var(--bodyPrimaryTypeColor);
    fill: var(--bodyPrimaryTypeColor);
  }

  &__body {
    padding: 0 24px 24px 24px;
  }
}
