
:deep(.text-primary) {
  color: var(--mainColor) !important;
}
.form {
  &__input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
  &__delete-btn {
    width: auto;
  }
  &__input {
    flex-grow: 1;
  }

  &__btn {
    width: 100%;
  }
}
